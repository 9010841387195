import cornerstoneTools from 'cornerstone-tools';

var estado = false;
/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 * Lineas de escala.
 */
class Escala {
  //Constructor:
  constructor() {}

  //Carga las lineas de escala:
  cargarEscala() {
    //Activar:
    if (!estado) {
      cornerstoneTools.setToolEnabled('ScaleOverlay');
      estado = true;
    } else {
      cornerstoneTools.setToolDisabled('ScaleOverlay');
      estado = false;
    }
  }

  //Hay que poner el estado a false al deshabilitar cuando se da al boton de descargar:
  static estado() {
    //Deshabiltamos la herramienta:
    cornerstoneTools.setToolDisabled('ScaleOverlay');
    estado = false;
  }
}

export default Escala;
