/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 * Vista Para movil.
 */
class VistaMovil {
  constructor() {}

  //comprueba si la vista es en modo movil y si es asi la fuerza para que se abra como en escritorio
  static cambiarVistaMovil() {
    //Ver si es movil:
    let vista = VistaMovil.esVistaMovil();
    if (vista) {
      let viewport = document.querySelector('meta[name=viewport]');
      viewport.setAttribute('content', 'width=1024');
    }
  }

  //Devuelve si es vista movil o no:
  //True es movil, false ordenador:
  static esVistaMovil() {
    let vista = false;
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      vista = true;
      //*Y oculto el info dicom y formato ya que no tiene sentdo que aparzca:
    }
    return vista;
  }
}

export default VistaMovil;
