import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import './StudyBrowser.styl';
import ArchivosIncompatibles from '../../../../viewer/src/misProgramas/archivosIncompatibles/arhivosIncompatibles.js';

//----------------------------------------------------------------------------------------------
//Variable aux para poner una linea si se abren varios estudios poder separarlos.
var aux;
var primeraVez = false;
//Lee el tipo de arhivos que no son compatibles:
let archvosIncompatibles = new ArchivosIncompatibles();

//Comprueba si hay mass estudios con la variable auxiliar, devuelve true si hay mas studios
//Recive el numero de estudio 0, 1, 2...para generar una clase con ese numero y poder luego mostrar u ocultar los datos correspondientes cuando de a la flecha.
function masEstudios(studio, studyMetadata, numEstudio) {
  //Primera vez:
  if (!primeraVez) {
    //Guardo el StudyInstanceUID en aux y si este cambia es que hay otro estudio:
    aux = studio;
    primeraVez = true;
    return true;
  }
  //Si a cambiado es que hay otro estudio:
  if (studio != aux) {
    aux = studio;
    //Datos del paciente a mostrar:
    let nombre = studyMetadata.PatientName;
    //Si el nombre tiene 22 o mas caracteres lo acortamos con ...
    if (nombre.length >= 22) {
      nombre = nombre.substr(0, nombre.length - 4);
      nombre += '...';
    }
    //Y la fecha:
    let fecha = studyMetadata.StudyDate;
    let fechaFormato = fechaFormat(fecha);
    //Añado el hospital del que viene, como no tengo acceso al server tendre que sacarlo del wadouri
    let wado = studyMetadata.wadoRoot;
    //Si abre varios ficheros en local:
    let hospital = 'LOCAL';
    if (wado) {
      let posicion = wado.indexOf('pacs') + 4;
      hospital = wado.substr(posicion, 4).toUpperCase();
    }
    //Generar vista:
    let studioVista = (
      <div className="studiosVista thumbnail">
        <div id="contenedor">
          <div>
            <text>{fechaFormato + ' (' + hospital + ')'}</text>
            <br />
            <text>{nombre}</text>
          </div>
          <div
            className={`contFlecha flecha${numEstudio}`}
            onClick={() => ocultarMostrarEstudios(numEstudio)}
          >
            <span className="flechaStudios" />
          </div>
        </div>
      </div>
    );
    return studioVista;
  } else {
    return false;
  }
}

//Ocultar o mostrar los estudios si se da al click:
function ocultarMostrarEstudios(numEstudio) {
  //Seleciono la clase id en concreto:
  let flechaStudio = document.getElementsByClassName('flecha' + numEstudio);
  let datosThumbnail = document.getElementsByClassName(
    'mostarOcularFlecha' + numEstudio
  );

  //vuelve al estado original y muestra los datos:
  if (flechaStudio[0].style.transform == 'rotate(-90deg)') {
    flechaStudio[0].style.transform = 'rotate(0deg)';
    for (let i = 0; i < datosThumbnail.length; i++) {
      datosThumbnail[i].style.visibility = 'visible';
      datosThumbnail[i].style.opacity = '1';
      datosThumbnail[i].style.height = '';
      datosThumbnail[i].style.transition = 'all 0.3s, visibility 0.3s';
    }
  } else {
    //Comprime las vistas:
    flechaStudio[0].style.transform = 'rotate(-90deg)';
    for (let i = 0; i < datosThumbnail.length; i++) {
      datosThumbnail[i].style.visibility = 'collapse';
      datosThumbnail[i].style.opacity = '0';
      datosThumbnail[i].style.height = '0%';
      datosThumbnail[i].style.transition = 'all 0.3s, visibility 0.3s';
    }
  }
}

//La fecha viene en formato yyyymmdd hay que trasformarla:
function fechaFormat(str) {
  //Si viene sin fecha ponemos 01/01/1900
  if (str != '' && str != undefined) {
    let y = str.substr(0, 4);
    let m = str.substr(4, 2);
    let d = str.substr(6, 2);
    return d + '/' + m + '/' + y;
  } else {
    return '01/01/1900';
  }
}

//Interfaz por defecto para mostrar los datos, lo separo para poder unir si tiene mas estudios en un fragment:
function interfazDefault(
  study,
  studyIndex,
  supportsDrag,
  StudyInstanceUID,
  onThumbnailClick,
  onThumbnailDoubleClick
) {
  return study.thumbnails.map((thumb, thumbIndex) => {
    // TODO: Thumb has more props than we care about?
    const {
      active,
      altImageText,
      displaySetInstanceUID,
      imageId,
      derivedDisplaySetsNumber,
      numImageFrames,
      SeriesDescription,
      SeriesNumber,
      hasWarnings,
      hasDerivedDisplaySets,
    } = thumb;
    //TODO
    //Quito los SR y DOC hasta que se aplique toda la API DicomWEBm
    //Quito tambien los  Informe de dosis y report data ya que estos fallan, (tienen que solucionarlo)
    let encontradoSerie = false;
    let encontradoImg = false;
    if (SeriesDescription != null) {
      encontradoSerie = archvosIncompatibles.existeEnSeries(SeriesDescription);
    }
    if (altImageText != null) {
      encontradoImg = archvosIncompatibles.existeEnImg(altImageText);
    }
    if (!encontradoSerie && !encontradoImg) {
      return (
        <div
          key={thumb.displaySetInstanceUID}
          className="thumbnail-container"
          data-cy="thumbnail-list"
        >
          <Thumbnail
            active={active}
            supportsDrag={supportsDrag}
            key={`${studyIndex}_${thumbIndex}`}
            id={`${studyIndex}_${thumbIndex}`} // Unused?
            // Study
            StudyInstanceUID={StudyInstanceUID} // used by drop
            // Thumb
            altImageText={altImageText}
            imageId={imageId}
            derivedDisplaySetsNumber={derivedDisplaySetsNumber}
            displaySetInstanceUID={displaySetInstanceUID} // used by drop
            numImageFrames={numImageFrames}
            SeriesDescription={SeriesDescription}
            SeriesNumber={SeriesNumber}
            hasWarnings={hasWarnings}
            hasDerivedDisplaySets={hasDerivedDisplaySets}
            // Events
            onClick={onThumbnailClick.bind(undefined, displaySetInstanceUID)}
            onDoubleClick={onThumbnailDoubleClick}
          />
        </div>
      );
    }
  });
}

//----------------------------------------------------------------------------------------------

function StudyBrowser(props) {
  const {
    studies,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
    //Guardo tambien los study metadata para poner el nombre del paciente:
    studyMetadata,
  } = props;
  return (
    <div className="study-browser">
      <div className="scrollable-study-thumbnails">
        {studies
          .map((study, studyIndex) => {
            const { StudyInstanceUID } = study;
            //Si tiene mas de un estudio junto en un fragment los estudios y el resultado, sino solo muestro el resultado:
            if (studyMetadata.length > 1) {
              return (
                <Fragment>
                  {masEstudios(
                    StudyInstanceUID,
                    studyMetadata[studyIndex],
                    studyIndex
                  )}
                  <div className={`mostarOcularFlecha${studyIndex}`}>
                    {interfazDefault(
                      study,
                      studyIndex,
                      supportsDrag,
                      StudyInstanceUID,
                      onThumbnailClick,
                      onThumbnailDoubleClick
                    )}
                  </div>
                </Fragment>
              );
            } else {
              //sino cargo solo la interfaz por defecto:
              return interfazDefault(
                study,
                studyIndex,
                supportsDrag,
                StudyInstanceUID,
                onThumbnailClick,
                onThumbnailDoubleClick
              );
            }
          })
          .flat()}
      </div>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          derivedDisplaySetsNumber: PropTypes.number,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
  showThumbnailProgressBar: PropTypes.bool,
};

StudyBrowser.defaultProps = {
  studies: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  showThumbnailProgressBar: true,
};

export { StudyBrowser };
