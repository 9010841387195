import cornerstone from 'cornerstone-core';

var listaColores = cornerstone.colors.getColormapsList();
var porDefecto = false;
var vistas = [];
/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 */
class ColorMap {
  constructor() {}

  //Cada vez que se de click se cambiara al color sigiente:
  //IMPORTANTE, el gray es el por defecto.
  cambioColor() {
    //Seleciono el elemento activado y el viewport del metodo statico que tengo:
    let element = ColorMap.elementoyViewport().element;
    let viewport = ColorMap.elementoyViewport().viewport;
    let idColor;

    //El programa peta cuando las imagenes son ECOGRAFIAS DE MAMA y pone Breast en la descripcion al dar a color, asi que si pone breast no va a cambiar el color.
    //Tambien peta con mas formatos como, densionometrias e imagenes, estas hay que bloquearlas.
    let activar = ColorMap.formatoInvalido(element);
    //Si se puede activar:
    if (activar) {
      //Si es la primera vesta la agrego con 0 y el color principal el 0:
      if (vistas.length == 0) {
        this.agregarVista(0, element, viewport, 0);
      } else {
        //Compruebo si se ha agregado antes:
        let agregadoAntes = this.agregadoAntes(element);
        //si no se ha agregado antes añadimos con el id siguente:
        if (!agregadoAntes) {
          //Si no ha sido agregado antes le agrego con el color principal a 0:
          let siguenteID = vistas.length + 1;
          this.agregarVista(siguenteID, element, viewport, 0);
        }
      }
      //Saco el id que tiene la vista actual:
      let idVista = ColorMap.idVista(element);

      //El 6 es el por defecto, pero como no esta en orden le odmitimos, y hacemos que aparezca al final:
      if (vistas[idVista].contadorVistas == 6) {
        //Saltamos el 6:
        vistas[idVista].contadorVistas++;
      }
      //Si es por defecto ponemos el gris:
      if (porDefecto) {
        ColorMap.colorDefecto(idVista, viewport, element);
      } else {
        //Recivo el id del color en base al contador:
        idColor = listaColores[vistas[idVista].contadorVistas].id;
        //Cambio el color:
        ColorMap.cambiarColor(idColor, viewport, element);
        //Cuando cambio el color sumo al contador para asi mostrar la siguiente vez el siguente color:
        //si el contador llega al maximo vuelve a 0:
        if (vistas[idVista].contadorVistas == listaColores.length - 1) {
          vistas[idVista].contadorVistas = 0;
          //Activamos para que salga el gris la siguente vez:
          porDefecto = true;
        } else {
          vistas[idVista].contadorVistas++;
        }
      }
    }
  }

  //Cambia el color por el ID que le pasamos:
  static cambiarColor(id, viewport, element) {
    //Saco el color:
    let color = cornerstone.colors.getColormap(id);
    //Cambio el color y refresco la vista con el elemento:
    viewport.colormap = color;
    cornerstone.setViewport(element, viewport);
    cornerstone.updateImage(element, true);
  }

  //Vuelve al estado original, es decir, nada.
  //Si trae index hay que selecionar el elemento de con ese index:
  static async quitarColorMap(index) {
    try {
      let element;
      let viewport;
      //Si tiene index:
      if (index) {
        element = this.elementoyViewport(index).element;
        viewport = this.elementoyViewport(index).viewport;
      } else {
        //Seleciono el elemento activado y el viewport:
        element = this.elementoyViewport().element;
        viewport = this.elementoyViewport().viewport;
      }
      //Pongo el colormap por defecto, si no esta asi de antes, sino no hago nada:
      if (viewport.colormap) {
        //Lo pongo por defecto que es no definido:
        viewport.colormap = undefined;
        await cornerstone.setViewport(element, viewport);
        await cornerstone.updateImage(element, true);

        //Quito el elemento:
        let id = this.idVista(element);
        vistas.pop(id);
        //Refresco la vista del canvas:
        this.refrescarVista(element);
      }
    } catch (e) {
      //No hacer nada, se pone por si se dropea un elemento sobre un viewport vacio, para que no casque:
    }
  }

  //Pone el color por defecto:
  static colorDefecto(id, viewport, element) {
    //Pongo el color por defecto que es el 6:
    vistas[id].contadorVistas = 6;
    porDefecto = false;
    let idColor = listaColores[vistas[id].contadorVistas].id;
    ColorMap.cambiarColor(idColor, viewport, element);
    //Y volvemos al contador 0:
    vistas[id].contadorVistas = 0;
  }

  //Reset para cuando se da a restaurar, devuelve si se puede restaurar o no.
  static reset(element, id) {
    //Lo primero mira si es un formato valido para cambiar color, ya que si no es asi no se ha cambiado de color:
    let activar = ColorMap.formatoInvalido(element);
    //Se comprueba si tiene por id activado algun color, si es asi se resetea:
    let vista = vistas[id];
    //Comprubea que sea valido el cambio, que tenga vista y que esta vista sea igual que la selecionada:
    if (activar && vista && vista.element == element) {
      ColorMap.colorDefecto(id, vista.viewport, element);
    }
  }

  //Sirve para refrescar la vista del canvas:
  static refrescarVista(element) {
    const enabledElement = cornerstone.getEnabledElement(element);

    enabledElement.renderingTools.colormapId = undefined;
    enabledElement.renderingTools.colorLut = undefined;

    const renderCanvas = enabledElement.renderingTools.renderCanvas;
    const canvasContext = renderCanvas.getContext('2d');

    // NOTE - we need to fill the render canvas with white pixels since we
    // control the luminance using the alpha channel to improve rendering performance.
    canvasContext.fillStyle = 'white';
    canvasContext.fillRect(0, 0, renderCanvas.width, renderCanvas.height);

    const renderCanvasData = canvasContext.getImageData(
      0,
      0,
      renderCanvas.width,
      renderCanvas.height
    );

    enabledElement.renderingTools.renderCanvasContext = canvasContext;
    enabledElement.renderingTools.renderCanvasData = renderCanvasData;
  }

  //Elemento activado o por index:
  static elementoyViewport(index) {
    let vista;
    let element;
    let viewport;
    if (index) {
      //Seleciono el elemento con index en concreto::
      vista = Array.from(
        document.getElementsByClassName(
          'viewport-drop-target viewport-container'
        )
      );
      element = vista[index].children[2].children[1];
    } else {
      //Seleciono el elemento activado:
      vista = Array.from(
        document.getElementsByClassName(
          'viewport-drop-target viewport-container active'
        )
      );
      element = vista[0].children[2].children[1];
    }
    //Del elemento lo saco con cornestone el viewport:
    viewport = cornerstone.getViewport(element);
    return { element, viewport };
  }

  //Agrego la vista para tener controlado el color que tiene cada vista:
  agregarVista(idViewport, element, viewport, contadorVistas) {
    //Temenos que ver si ha sido agregado antes:
    let datos = {
      idViewport: idViewport,
      element: element,
      viewport: viewport,
      contadorVistas: contadorVistas,
    };
    vistas.push(datos);
  }

  //Compruebo si ha sido agregado antes o no el elemento a mi lista de vistas:
  agregadoAntes(element) {
    for (let i = 0; i < vistas.length; i++) {
      if (vistas[i].element == element) {
        return true;
      }
    }
    return false;
  }

  //Saco el id de la vista que se esta usando actualmente:
  static idVista(element) {
    for (let i = 0; i < vistas.length; i++) {
      if (vistas[i].element == element) {
        return i;
      }
    }
  }

  //Comprueba si el formato del archivo puede cambiar de color o no
  static formatoInvalido(element) {
    let texto = element.children[1].children[1].children[0].innerText;
    //si es el que peta o no tiene nada o es una ecografia o  es densionometria o es imagen:
    if (
      texto == '' ||
      texto.search(/Breast/i) != -1 ||
      texto.search(/ecografia/i) != -1 ||
      texto.search(/DXA/i) != -1 ||
      texto.search(/IMAGEnet/i) != -1 ||
      texto.search(/PV Venous/i) != -1 ||
      texto.search(/Gastroscopia/i) != -1
    ) {
      return false;
    }
    return true;
  }
}
export default ColorMap;
