import OHIF from '@ohif/core';
import log from './../../../core/src/log.js';

/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 * Cifrado de la URL.
 */
class Cifrado {
  constructor() {}

  static cifrarURL() {
    //Cifra la URL al iniciar la ruta, esto recarga la imagen pero con un cifrado btoa, el programa contempla que si llega cifrado se puiede descifrar.
    //Estaria mejor hacerlo desde la parte del servidor.
    let UID = window.location.pathname.split('/').pop();
    //Si se abre el manual no cifra:
    if (UID != 'Manual%20IRE%20DICOM%20Viewer%20Lite.pdf') {
      let UID_Limpio = UID.split('?')[0];

      //si tiene token hay que cifrarlo:
      let token = UID.split('?token=')[1];
      if (token) {
        //Desncripta el token por si estan encriptado:
        var decode = decodeURIComponent(token);
        //si es igual es que no esta encriptado, lo cual lo encripta:
        if (decode == token) {
          var tokenCifrado = `?token=${encodeURIComponent(token)}`;
          window.location.search = tokenCifrado;
          this.borrarConsola();
        }
      }
      //Si abre en local, no hacer nada:
      if (UID_Limpio != 'local') {
        let cifradoSolo = Cifrado.cifrar(UID_Limpio);
        //Si es vacio es que ya esta cifrados los datos:
        if (cifradoSolo != '') {
          Cifrado.recargaCifrado(cifradoSolo);
        }
      }
    }
  }

  //Cifra los datos que le pasemos y comprueba si estaba antes cifrado o no:
  static cifrar(dato) {
    let cifrado = '';
    try {
      //si no esta cifrado entra en el catch y lo cifra:
      let decodedData = window.atob(dato);
      this.borrarConsola();
    } catch (e) {
      cifrado = window.btoa(dato);
    }
    //Si cifrado esta vacio no hace falta recargar:
    return cifrado;
  }

  //Recarga la vista con el archivo cifrado:
  static recargaCifrado(cifrado) {
    let ruta = '/viewer/';
    window.location.pathname = ruta + cifrado;
    this.borrarConsola();
  }

  static borrarConsola() {
    //Si esta en produccion borro el console log:
    if (process.env.NODE_ENV == 'production') {
      //Borramos los comandos internos que tiene OIF de log:
      log.debug = function() {};
      log.error = function() {};
      log.info = function() {};
      log.time = function() {};
      log.timeEnd = function() {};
      log.trace = function() {};
      log.warn = function() {};

      OHIF.log.error = function() {};
      OHIF.log.info = function() {};
      OHIF.log.warn = function() {};

      //Al iniciar el programa vamos a hacer que se quite el console.log
      console.log = function() {};
      console.warn = function() {};
      console.error = function() {};
      console.info = function() {};
      console.clear();
    }
  }
}

export default Cifrado;
