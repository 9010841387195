import cornerstoneTools from 'cornerstone-tools';
//Variable de sincronizacion, es global ya que se va a acceder desde varios sitios:
var synchronizer;

/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 */
class SyncImagenes {
  //Cosntructor:
  constructor() {
    //Primera vez que se ejecuta la sincronicacion:
    this.primeraVez = false;
  }

  //Crea la sincronizaciopn por primera vez:
  inicializarSyncPrimeraVez() {
    //Al dar init da un warning en consola de COLORLUT, para que no se llene la consola de warnings se agrega esto antes de incializar:
    cornerstoneTools.getModule(
      'segmentation'
    ).configuration.segmentsPerLabelmap = 0;
    //Iniciar corstone tools con la syncronizacion activada:
    cornerstoneTools.init({
      globalToolSyncEnabled: true,
    });

    synchronizer = new cornerstoneTools.Synchronizer(
      'cornerstonenewimage',
      //'cornerstoneimagerendered',
      cornerstoneTools.stackImagePositionSynchronizer
      //cornerstoneTools.updateImageSynchronizer
    );

    this.primeraVez = true;
  }

  //Inicializa la syncronizacion pasando la lista de imagenes:
  //Opcion va a ser true si se quiere agregar imagenes, false si no:
  //Siempre vamos a desuncronizar nada mas empezar, luego ya añadimos, ya que recive una lista de vieports que puede contener ya datos syncronizados:
  inicializarSync(listaVieport, opcion) {
    //Como siempre le paso la lista de vieport aunque alguno ya este syncronizado, vamos a desincronizar todo y luego volver a suncronizar:
    //En caso de que opcion sea false ya tenemos desincronizado todo.
    for (let i = 0; i < listaVieport.length; i++) {
      let imagenBorrar = listaVieport[i];
      //Ponemos try catch por que a la hora de borrar aunque no sea nulo puede que casque, asi evitamos que se llene el consolelog de datos:
      try {
        //Quitamos la syncronizacion:
        synchronizer.remove(imagenBorrar);
      } catch (e) {
        let a = e;
      }
    }

    //Si hay que syncronizar añadimos la lista, una vez ya quitada:
    if (opcion) {
      //Añado a la sync las imagenes:
      for (let b = 0; b < listaVieport.length; b++) {
        let imagen = listaVieport[b];
        synchronizer.add(imagen);
      }
      //Inizializar el boton de sincronizar:
      cornerstoneTools.setToolActive('Wwwc', {
        mouseButtonMask: 1,
        synchronizationContext: synchronizer,
      });
    }
  }

  //Agregar Imagenes a la sicnronizacion:
  agregarImgSync() {
    //si es la primera vez que incializa el metodo, hay que crear la syncronizacion:
    if (!this.primeraVez) {
      this.inicializarSyncPrimeraVez();
    }
    const listaVieport = this.verActivadosoNo(true);
    //Inicializo la syncronizacion para agregar imagenes pongo true:
    this.inicializarSync(listaVieport, true);
  }

  //Quitar Imagenes a la sicnronizacion:
  quitarImgSync() {
    const listaVieport = this.verActivadosoNo(false);
    //Quito las imagenes desmarcadas:
    this.inicializarSync(listaVieport, false);
    //Comprueba si hay algun checkbox activado o no, si no hay para el servicio:
    this.comprobarNingunaSYNC();
  }

  //Comprueba si la serie solo tiene 1 imagen o no, si solo tiene 1 quital el checkbox de sync:
  comprobarCheckBox() {
    //Devuelve true o false para renderizar en VieportPanel:
    let e = true;
    try {
      //Hay que meter un delay ya que no sincronzia al momento, por eso hay que dejarle 1ms para que carge la imagen y luego haga esta comprobacion:
      let checkExist = setInterval(function() {
        try {
          //Hay que recorrer todas las vistas conImagen:
          let vistas = document.getElementsByClassName('conImagenes');
          for (let i = 0; i < vistas.length; i++) {
            //Si no tiene imagenes oculta:
            if (vistas[i].innerText == '') {
              SyncImagenes.OcultarCheckSync(i);
            } else {
              document.getElementsByClassName('syncChk')[i].style.visibility =
                'visible';
              document.getElementsByClassName('syncChk')[i].style.display = '';
              document.getElementsByClassName('txtSync')[i].style.visibility =
                'visible';
              document.getElementsByClassName('txtSync')[i].style.display = '';
            }
          }
        } catch (err) {
          e = false;
        }
        clearInterval(checkExist);
      }, 100);
    } catch (error) {
      e = false;
    }
    return e;
  }

  //si no hay ninguna sync chekeada hay que deshabilitar la estancia ya que esto puede probocar que el programa renderize mal y pete:
  comprobarNingunaSYNC() {
    //Si no hay ninguno activado:
    let a = this.verActivadosoNo(true);
    if (a.length == 0) {
      synchronizer = null;
      this.primeraVez = false;
    }
  }

  //Saca la lista en array de los activados o no, si se pasa true saca los chekeados, si no se pasa los no chekeados:
  verActivadosoNo(activados) {
    //Selecionar las vistas:
    const vistas = Array.from(
      document.getElementsByClassName('viewport-drop-target viewport-container')
    );
    //De las vistas selecionamos los elementos que tengan un check:
    const listaVista = new Array();
    if (activados) {
      for (let i = 0; i < vistas.length; i++) {
        if (vistas[i].children[0].checked) {
          listaVista.push(vistas[i]);
        }
      }
    } else {
      for (let e = 0; e < vistas.length; e++) {
        if (!vistas[e].children[0].checked) {
          listaVista.push(vistas[e]);
        }
      }
    }
    //Añado los vieport-element
    const listaVieport = new Array();
    //Ahora seleciono solo los que estan chekeados o los que no:
    for (let a = 0; a < listaVista.length; a++) {
      listaVieport.push(listaVista[a].children[2].children[1]);
    }
    return listaVieport;
  }

  //Cuando se cambia la imagen por drop hay que comprobar si la imagen que se cambia esta activada o no, recive la posicion de la lista que se cambia:
  //Verifica si el id pasado esta activado o no, si no existe saltara al try catch:
  //Si esta ativado devuelve true, sino false;
  static cambioImagen(idActivado) {
    //Selecionar las vistas:
    try {
      const vistas = Array.from(
        document.getElementsByClassName(
          'viewport-drop-target viewport-container'
        )
      );
      //Si el de la vista esta chekeado:
      if (vistas[idActivado].children[0].checked) {
        //Desmarco el check, quito la syncronizacion:
        vistas[idActivado].children[0].checked = false;
        synchronizer.remove(vistas[idActivado].children[2].children[1]);
      }
    } catch (e) {
      let a = e;
    }
  }

  //Ocultar el checkbox:
  static OcultarCheckSync(index) {
    try {
      document.getElementsByClassName('syncChk')[index].style.visibility =
        'hidden';
      document.getElementsByClassName('syncChk')[index].style.display = 'none';
      document.getElementsByClassName('txtSync')[index].style.visibility =
        'hidden';
      document.getElementsByClassName('txtSync')[index].style.display = 'none';
    } catch {
      //Err.
    }
  }
}
export default SyncImagenes;
