import './OHIFLogo.css';
import React from 'react';

function OHIFLogo() {
  const style = {
    display: 'block',
    textIndent: '-9999px',
    background: 'url(/ire-rayos-x-logo.svg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '200px',
  };

  return (
    <a
      id="logotipo"
      target="_self"
      rel="noopener noreferrer"
      className="header-brand"
      //URL logotipo, dependera de clinicas:
      /*href="/"*/
      style={style}
    >
      {/*Logo creado en su sutio*/}
    </a>
  );
}

export default OHIFLogo;
