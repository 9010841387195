import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import OHIF from '@ohif/core';

import setCornerstoneLayout from './utils/setCornerstoneLayout.js';
import { getEnabledElement } from './state';
import CornerstoneViewportDownloadForm from './CornerstoneViewportDownloadForm';
//--------------------------------------------------------------------------------
// DESCARGAR FICHEROS DCM
//--------------------------------------------------------------------------------
import {
  save,
  //upload,
  getDicomWebClientFromContext,
  //getStudyInstanceUIDFromStudies,
  getSOPInstanceReferenceFromActiveViewport,
  getSOPInstanceReferencesFromViewports,
} from './../../../extensions/debugging/src/utils.js';
import _downloadAndZip from './../../debugging/src/downloadAndZip.js'; //downloadInstances,
import LineasReferencia from '../../../platform/viewer/src/misProgramas/lineasReferencia.js';
import Escala from '../../../platform/viewer/src/misProgramas/escala.js';
import Colormap from '../../../platform/viewer/src/misProgramas/colormap.js';
import Render3D from '../../../platform/viewer/src/misProgramas/render3D/render3D.js';
import React from 'react';
import ReactDOM from 'react-dom';
import setMPRLayout from './../../vtk/src/utils/setMPRLayout.js';
import ColorMap from '../../../platform/viewer/src/misProgramas/colormap.js';
import SyncWwwc from '../../../platform/viewer/src/misProgramas/syncWWWC/syncWwwc.js';
import SyncImagenes from '../../../platform/viewer/src/misProgramas/syncImagenes.js';

const {
  utils: { Queue },
} = OHIF;
const queue = new Queue(1);
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
const scroll = cornerstoneTools.import('util/scroll');

const { studyMetadataManager } = OHIF.utils;
const { setViewportSpecificData } = OHIF.redux.actions;

const refreshCornerstoneViewports = () => {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    if (enabledElement.image) {
      cornerstone.updateImage(enabledElement.element);
    }
  });
};

const commandsModule = ({ servicesManager }) => {
  //Inizializo mis clases para solo crearlas una vez:
  const escala = new Escala();
  const lineas = new LineasReferencia();
  const color = new Colormap();
  var syncWwwc = new SyncWwwc();

  const actions = {
    //Mis metodos:
    //---------------------------------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------------------------------
    //Lineas De Referencia
    lineas: () => {
      lineas.iniciarLineas();
    },
    //Escala:
    escala: () => {
      escala.cargarEscala();
    },
    //Color del viewport:
    color: () => {
      color.cambioColor();
    },

    //Pantalla completa:
    fullScreen: () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
      }
    },

    //Renderizado en 3D, abre una nueva ventana:
    render: async ({ viewports }) => {
      //Cargo como el 2D una nueva vista:
      const displaySet =
        viewports.viewportSpecificData[viewports.activeViewportIndex];

      //La vista que se va a mostrar:
      const viewportProps = [
        {
          //Vista 3D
          orientation: {
            sliceNormal: [0, 0, 1],
            viewUp: [0, -1, 0],
          },
        },
      ];

      //Cargar nueva vista con número de filas y columnas:
      try {
        await setMPRLayout(displaySet, viewportProps, 1, 1);
      } catch (error) {
        throw new Error(error);
      }

      //Seleciono la vista activada del visor 2D:
      const vistaActivada = Array.from(
        document.getElementsByClassName('vtk-viewport-handler')
      );

      //Borro la vista y renderizo:
      vistaActivada[0].innerHTML = '';
      //Elemento a renderizar:
      ReactDOM.render(<Render3D />, vistaActivada[0]);

      //Ahora quito los botones que no quiero que salgan del 2D, todo esto es mas sencillo que crear de nuevo guardar la vista anterior...
      Render3D.botones(false);

      //Ocultamos el check:
      SyncImagenes.OcultarCheckSync(0);
    },

    //---------------------------------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------------------------------

    rotateViewport: ({ viewports, rotation }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.rotation += rotation;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    flipViewportHorizontal: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.hflip = !viewport.hflip;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    flipViewportVertical: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.vflip = !viewport.vflip;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    scaleViewport: ({ direction, viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      const step = direction * 0.15;

      if (enabledElement) {
        if (step) {
          let viewport = cornerstone.getViewport(enabledElement);
          viewport.scale += step;
          cornerstone.setViewport(enabledElement, viewport);
        } else {
          cornerstone.fitToWindow(enabledElement);
        }
      }
    },
    resetViewport: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        cornerstone.reset(enabledElement);
        //Quita el colormap si esta activado al dar reset:
        ColorMap.reset(enabledElement, viewports.activeViewportIndex);
      }
    },
    invertViewport: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.invert = !viewport.invert;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    // TODO: this is receiving `evt` from `ToolbarRow`. We could use it to have
    //       better mouseButtonMask sets.
    setToolActive: ({ toolName }) => {
      if (!toolName) {
        console.warn('No toolname provided to setToolActive command');
      }
      cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
      //Si la herramienta es WWWC se activa el botón secundario:
      if (toolName == 'Wwwc') {
        //Crear boton:
        syncWwwc.activar();
      } else {
        //Desactivar:
        syncWwwc.desactivar();
      }
    },
    clearAnnotations: ({ viewports }) => {
      const element = getEnabledElement(viewports.activeViewportIndex);
      if (!element) {
        return;
      }

      const enabledElement = cornerstone.getEnabledElement(element);
      if (!enabledElement || !enabledElement.image) {
        return;
      }

      const {
        toolState,
      } = cornerstoneTools.globalImageIdSpecificToolStateManager;
      if (
        !toolState ||
        toolState.hasOwnProperty(enabledElement.image.imageId) === false
      ) {
        return;
      }

      const imageIdToolState = toolState[enabledElement.image.imageId];

      const measurementsToRemove = [];

      Object.keys(imageIdToolState).forEach(toolType => {
        const { data } = imageIdToolState[toolType];

        data.forEach(measurementData => {
          const {
            _id,
            lesionNamingNumber,
            measurementNumber,
          } = measurementData;
          if (!_id) {
            return;
          }

          measurementsToRemove.push({
            toolType,
            _id,
            lesionNamingNumber,
            measurementNumber,
          });
        });
      });

      measurementsToRemove.forEach(measurementData => {
        OHIF.measurements.MeasurementHandlers.onRemoved({
          detail: {
            toolType: measurementData.toolType,
            measurementData,
          },
        });
      });
    },
    nextImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, 1);
    },
    previousImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, -1);
    },
    getActiveViewportEnabledElement: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      return enabledElement;
    },
    showDownloadViewportModal: ({ title, viewports }) => {
      const activeViewportIndex = viewports.activeViewportIndex;
      const { UIModalService } = servicesManager.services;
      if (UIModalService) {
        UIModalService.show({
          content: CornerstoneViewportDownloadForm,
          title,
          contentProps: {
            activeViewportIndex,
            onClose: UIModalService.hide,
          },
        });
      }
    },
    updateTableWithNewMeasurementData({
      toolType,
      measurementNumber,
      location,
      description,
    }) {
      // Update all measurements by measurement number
      const measurementApi = OHIF.measurements.MeasurementApi.Instance;
      const measurements = measurementApi.tools[toolType].filter(
        m => m.measurementNumber === measurementNumber
      );

      measurements.forEach(measurement => {
        measurement.location = location;
        measurement.description = description;

        measurementApi.updateMeasurement(measurement.toolType, measurement);
      });

      measurementApi.syncMeasurementsAndToolData();

      refreshCornerstoneViewports();
    },
    getNearbyToolData({ element, canvasCoordinates, availableToolTypes }) {
      const nearbyTool = {};
      let pointNearTool = false;

      availableToolTypes.forEach(toolType => {
        const elementToolData = cornerstoneTools.getToolState(
          element,
          toolType
        );

        if (!elementToolData) {
          return;
        }

        elementToolData.data.forEach((toolData, index) => {
          let elementToolInstance = cornerstoneTools.getToolForElement(
            element,
            toolType
          );

          if (!elementToolInstance) {
            elementToolInstance = cornerstoneTools.getToolForElement(
              element,
              `${toolType}Tool`
            );
          }

          if (!elementToolInstance) {
            console.warn('Tool not found.');
            return undefined;
          }

          if (
            elementToolInstance.pointNearTool(
              element,
              toolData,
              canvasCoordinates
            )
          ) {
            pointNearTool = true;
            nearbyTool.tool = toolData;
            nearbyTool.index = index;
            nearbyTool.toolType = toolType;
          }
        });

        if (pointNearTool) {
          return false;
        }
      });

      return pointNearTool ? nearbyTool : undefined;
    },
    removeToolState: ({ element, toolType, tool }) => {
      cornerstoneTools.removeToolState(element, toolType, tool);
      cornerstone.updateImage(element);
    },
    setCornerstoneLayout: () => {
      //Si estaba el 3D hay que volver visible los botones:
      Render3D.botones(true);
      setCornerstoneLayout();
    },
    setWindowLevel: ({ viewports, window, level }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);

      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);

        viewport.voi = {
          windowWidth: Number(window),
          windowCenter: Number(level),
        };
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    jumpToImage: ({
      StudyInstanceUID,
      SOPInstanceUID,
      frameIndex,
      activeViewportIndex,
      refreshViewports = true,
    }) => {
      const study = studyMetadataManager.get(StudyInstanceUID);

      const displaySet = study.findDisplaySet(ds => {
        //Se pone try catch por que el usuario puede dar a una vista que no ha terminado de cargar:
        try {
          return (
            ds.images &&
            ds.images.find(i => i.getSOPInstanceUID() === SOPInstanceUID)
          );
        } catch (e) {
          //Error
        }
      });

      if (!displaySet) {
        return;
      }

      displaySet.SOPInstanceUID = SOPInstanceUID;
      displaySet.frameIndex = frameIndex;

      window.store.dispatch(
        setViewportSpecificData(activeViewportIndex, displaySet)
      );

      if (refreshViewports) {
        refreshCornerstoneViewports();
      }
    },

    //--------------------------------------------------------------------------------
    // DESCARGAR FICHEROS DCM
    //--------------------------------------------------------------------------------
    //Descargar todo el estudio:
    downloadAndZip({ servers, dicomWebClient, listOfUIDs, options }) {
      return save(
        _downloadAndZip(
          dicomWebClient || getDicomWebClientFromContext(this, servers),
          listOfUIDs,
          options
        ),
        listOfUIDs
      );
    },
    //Descargar las series en pantalla:
    downloadAndZipSeriesOnViewports({ servers, viewports, progress }) {
      const dicomWebClient = getDicomWebClientFromContext(this, servers);
      //Este metodo esta bug, a veces coje la imagen solo mostrada en pantalla y a veces todo.
      //Para que coja todo vamos a borrar del array el ultimo elemento, por si acaso:
      const listOfUIDs = getSOPInstanceReferencesFromViewports(viewports);
      //Si ha selecionado una imagen borramos esa ultima imagen para que descarge solo la serie al completo:
      //Con esto forzamos a que siempre descarge las series:
      //Vemos todos los que hay para saber si hay que borrar:
      var borrar = false;
      if (listOfUIDs[0][1].length > 0) {
        for (var a = 0; a < listOfUIDs[0][1].length; a++) {
          if (listOfUIDs[0][1][a][1].length > 0) {
            borrar = true;
          }
        }
      }
      //Si se tiene que borrar:
      if (borrar) {
        //Puede tener varios vieports:
        for (var i = 0; i < listOfUIDs[0][1].length; i++) {
          listOfUIDs[0][1][i][1] = [];
        }
      }
      return save(
        _downloadAndZip(dicomWebClient, listOfUIDs, { progress }),
        listOfUIDs
      );
    },
    //Descargar la imagen en pantalla:
    downloadAndZipInstance({ servers, viewports, progress }) {
      const dicomWebClient = getDicomWebClientFromContext(this, servers);
      var listOfUIDs;
      //A veces listUID no lo encuentra asi que lo forzamos a que busce hasta que lo encuentre:
      var encontrado = false;
      var contador = 0;
      //Ponemos un contador por si tardara mucho y asi salir del bucle y avisar del error:
      while (!encontrado || contador == 500) {
        listOfUIDs = getSOPInstanceReferenceFromActiveViewport(viewports);
        //Si tiene mas de un dato es que lo encontro:
        if (listOfUIDs[0][1].length > 0) {
          encontrado = true;
        } else {
          contador++;
          if (contador == 500) {
            alert('Error en la descarga, intentelo en un rato.');
          }
        }
      }

      return save(
        _downloadAndZip(dicomWebClient, listOfUIDs, { progress }),
        listOfUIDs
      );
    },
    /*
    downloadAndZipStudy({ servers, studies, progress }) {
      const dicomWebClient = getDicomWebClientFromContext(this, servers);
      const listOfUIDs = getStudyInstanceUIDFromStudies(studies);
      return save(
        _downloadAndZip(dicomWebClient, listOfUIDs, { progress }),
        listOfUIDs
      );
    },
    downloadAndZipSeriesOnActiveViewport({ servers, viewports, progress }) {
      const dicomWebClient = getDicomWebClientFromContext(this, servers);
      const listOfUIDs = getSOPInstanceReferenceFromActiveViewport(viewports);
      return save(
        _downloadAndZip(dicomWebClient, listOfUIDs, { progress }),
        listOfUIDs
      );
    },
    downloadAndUploadStudy({ servers, studies, progress, serverConfig }) {
      const dicomWebClient = getDicomWebClientFromContext(this, servers);
      const listOfUIDs = getStudyInstanceUIDFromStudies(studies);
      return upload(
        downloadInstances(dicomWebClient, listOfUIDs, { progress }),
        /**
         * serverConfig is an object with the values used to create a new
         * instance of DICOMwebClient.
         *
         * Basic Structure:
         *
         * const config = {
         *    url,
         *    headers,
         *    errorInterceptor
         * }
         *
         * const dicomWeb = new api.DICOMwebClient(config);

        serverConfig
      );
    },*/
    //--------------------------------------------------------------------------------
    //--------------------------------------------------------------------------------
  };

  const definitions = {
    jumpToImage: {
      commandFn: actions.jumpToImage,
      storeContexts: [],
      options: {},
    },
    getNearbyToolData: {
      commandFn: actions.getNearbyToolData,
      storeContexts: [],
      options: {},
    },
    removeToolState: {
      commandFn: actions.removeToolState,
      storeContexts: [],
      options: {},
    },
    updateTableWithNewMeasurementData: {
      commandFn: actions.updateTableWithNewMeasurementData,
      storeContexts: [],
      options: {},
    },
    showDownloadViewportModal: {
      commandFn: actions.showDownloadViewportModal,
      storeContexts: ['viewports'],
      options: {},
    },
    getActiveViewportEnabledElement: {
      commandFn: actions.getActiveViewportEnabledElement,
      storeContexts: ['viewports'],
      options: {},
    },
    rotateViewportCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: 90 },
    },
    rotateViewportCCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: -90 },
    },
    invertViewport: {
      commandFn: actions.invertViewport,
      storeContexts: ['viewports'],
      options: {},
    },
    flipViewportVertical: {
      commandFn: actions.flipViewportVertical,
      storeContexts: ['viewports'],
      options: {},
    },
    flipViewportHorizontal: {
      commandFn: actions.flipViewportHorizontal,
      storeContexts: ['viewports'],
      options: {},
    },
    scaleUpViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: 1 },
    },
    scaleDownViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: -1 },
    },
    fitViewportToWindow: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: 0 },
    },
    resetViewport: {
      commandFn: actions.resetViewport,
      storeContexts: ['viewports'],
      options: {},
    },
    clearAnnotations: {
      commandFn: actions.clearAnnotations,
      storeContexts: ['viewports'],
      options: {},
    },
    nextImage: {
      commandFn: actions.nextImage,
      storeContexts: ['viewports'],
      options: {},
    },
    previousImage: {
      commandFn: actions.previousImage,
      storeContexts: ['viewports'],
      options: {},
    },
    // TOOLS
    setToolActive: {
      commandFn: actions.setToolActive,
      storeContexts: [],
      options: {},
    },
    setZoomTool: {
      commandFn: actions.setToolActive,
      storeContexts: [],
      options: { toolName: 'Zoom' },
    },
    setCornerstoneLayout: {
      commandFn: actions.setCornerstoneLayout,
      storeContexts: [],
      options: {},
      context: 'VIEWER',
    },
    setWindowLevel: {
      commandFn: actions.setWindowLevel,
      storeContexts: ['viewports'],
      options: {},
    },

    //----------------------------------------------
    //Lineas de referencia:
    //----------------------------------------------
    lineas: {
      commandFn: actions.lineas,
      storeContexts: [],
      options: {},
    },
    //Escala:
    escala: {
      commandFn: actions.escala,
      storeContexts: [],
      options: {},
    },
    //Color:
    color: {
      commandFn: actions.color,
      storeContexts: [],
      options: {},
    },
    //Render 3D:
    render: {
      commandFn: actions.render,
      storeContexts: ['viewports'],
      options: {},
      context: 'VIEWER',
    },
    //Pantalla completa:
    fullScreen: {
      commandFn: actions.fullScreen,
      storeContexts: [],
      options: {},
    },
    //--------------------------------------------------------------------------------
    // DESCARGAR FICHEROS DCM
    //--------------------------------------------------------------------------------
    //Descargar todo el estudio:
    downloadAndZip: {
      commandFn: queue.bindSafe(actions.downloadAndZip, error),
      storeContexts: ['servers'],
    },
    //Descargar las series mostradas en pantalla:
    downloadAndZipSeriesOnViewports: {
      commandFn: queue.bindSafe(actions.downloadAndZipSeriesOnViewports, error),
      storeContexts: ['servers', 'viewports'],
      options: { progress },
    },
    //Descargar la imagen en pantalla:
    downloadAndZipInstance: {
      commandFn: queue.bindSafe(actions.downloadAndZipInstance, error),
      storeContexts: ['servers', 'viewports'],
      options: { progress },
    },
    /*
    downloadAndZipStudy: {
      commandFn: queue.bindSafe(actions.downloadAndZipStudy, error),
      storeContexts: ['servers', 'studies'],
      options: { progress },
    },
    downloadAndZipSeriesOnActiveViewport: {
      commandFn: queue.bindSafe(
        actions.downloadAndZipSeriesOnActiveViewport,
        error
      ),
      storeContexts: ['servers', 'viewports'],
      options: { progress },
    },
    downloadAndUploadStudy: {
      commandFn: queue.bindSafe(actions.downloadAndUploadStudy, error),
      storeContexts: ['servers', 'studies'],
      options: { progress },
    },*/
    //--------------------------------------------------------------------------------
    //--------------------------------------------------------------------------------
  };

  //----------------------------------------------------------------------------------
  // DESCARGAR FICHEROS DCM
  //----------------------------------------------------------------------------------
  /**
   * Utils
   */
  function progress(status) {
    OHIF.log.info(
      'Download and Zip Progress:',
      (status.progress * 100.0).toFixed(2) + '%'
    );
  }

  function error(e) {
    if (e.message === 'Queue limit reached') {
      OHIF.log.warn('A download is already in progress, please wait.');
    } else {
      OHIF.log.error(e);
    }
  }
  //--------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------

  return {
    actions,
    definitions,
    defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
  };
};

export default commandsModule;
