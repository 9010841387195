import cornerstone from 'cornerstone-core';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 */
class ImagenesDownload {
  //Constructor:
  constructor() {}

  //Descarga todas las imagenes:
  descargarTodasLasImagenes() {
    let UID_Limpio = this.nombreEstudio();
    console.log(
      ohif.app.commandsManager.runCommand('downloadAndZip', {
        listOfUIDs: [UID_Limpio],
      })
    );
    this.alerta();
  }

  nombreEstudio() {
    //Selecona de la web el StudyUID:
    let UID = window.location.href.split('/').pop();
    //Como a veces puede tener mas datos en la ruta, puede aparecer ?token, asi que lo quitamos para que no nos de error.
    let UID_Limpio = UID.split('?')[0];
    return UID_Limpio;
  }

  //Descarga las series abiertas actualmente:
  descargarSerieActual() {
    console.log(
      ohif.app.commandsManager.runCommand('downloadAndZipSeriesOnViewports')
    );
    this.alerta();
  }

  //Descarga la imagen abierta actualmente:
  descargarImagenActual() {
    console.log(ohif.app.commandsManager.runCommand('downloadAndZipInstance'));
    this.alerta();
  }

  alerta() {
    alert(
      'Descargando...Esto puede tardar. Puede seguir usando la aplicación.'
    );
  }

  //Saca el ancho y alto de la imagen actual para ponerla en la vista de descarga:
  static tamano() {
    let ancho;
    let alto;
    let vistaActivada = Array.from(
      document.getElementsByClassName(
        'viewport-drop-target viewport-container active'
      )
    );

    //Lo primero es el ancho y luego el alto, viene junto asi que hay que separarlo:
    let tamanoImg = vistaActivada[0].getElementsByClassName('tamano')[0]
      .innerText;

    let posicionX = tamanoImg.search('x');
    //Restamos 1 ya que hay un espacio en blanco '200 x 200':
    ancho = tamanoImg.substr(0, posicionX - 1);
    alto = tamanoImg.substr(posicionX + 2, tamanoImg.length);
    return { ancho, alto };
  }

  //------------------------------------------------------------------------------------------------
  //PROVISIONAL ESTO SOLO ES PROVISIONAL HASTA QUE SE CAMBIE EL DICOMWEB DESDE EL SERVIDOR YA QUE NO PERMITE LAS PETICIONDES DICOMWEB, CON LO CUAL SOLO PODEMOS DESCARGR LA IMAGENA ACTUAL Y RENOMBRARLA CON ESTOS METODOS.
  descargaUnaImagen() {
    let vista = Array.from(
      document.getElementsByClassName(
        'viewport-drop-target viewport-container active'
      )
    );
    let element = vista[0].children[2].children[1];
    const enabledElement = cornerstone.getEnabledElement(element);
    let rutaImagen = enabledElement.image.sharedCacheKey;
    let nombre = ImagenesDownload.textoImagen(rutaImagen);
    //Carga la imagen con el nombre:
    this.load(rutaImagen, nombre);
  }

  //Descarga las series actuales con mi metodo:
  descargarSeriesActuales() {
    //Mostrar alerta de que puede tardar:
    this.alerta();
    //Primero sacaremos el numero de estudios concreto:
    let vista = Array.from(
      document.getElementsByClassName('viewport-drop-target viewport-container')
    );
    //De estos sacamos sus datos que tenemos almacenados en local:
    let datos = [];
    let numEstudios = vista.length;
    for (let i = 0; i < numEstudios; i++) {
      let textoGuardado = 'viewportData' + i;
      datos[i] = JSON.parse(localStorage.getItem(textoGuardado));
    }

    //Busco si tiene archivos identicos, ya qyue puede haber abierto 2 o mas series iguales, lo cual solo tendria que descargarla 1 sola vez:
    datos = this.busquedaIdenticos(datos);
    //Creo mi lista de descargas con el nombre que va a tener, se crea yb array con la serie y la lista de sus descargas:
    let datosFinales = [];
    for (let e = 0; e < datos.length; e++) {
      //Puede que haya vista en null, asi que controlo que solo carge los datos que tiene:
      if (datos[e]) {
        //Saco de la lista la ruta de imagenes:
        let rutaImagenes = [];
        for (var a = 0; a < datos[e].stack.imageIds.length; a++) {
          let ruta = datos[e].stack.imageIds[a]; //pone antes del http dicomweb, eso hay que quitarlo.
          let rutaBuena = ruta.slice(9, ruta.length);
          rutaImagenes.push(rutaBuena);
        }
        //Saco el uid de la serie:
        let serieAnadir = this.nombreSerie(datos[e].stack.imageIds[0]);
        //Cuando acaba de añadir una serie añado los datos a datos finales:
        let datosAnadir = {
          serie: serieAnadir,
          imageIds: rutaImagenes,
        };
        datosFinales.push(datosAnadir);
      }
    }

    //Creo el zip, con los datos y carpetas que tiene que crear:
    this.crearZip(datosFinales);
  }

  //Busca si tiene datos identicos y si es asi los borra de la lista:
  busquedaIdenticos(datos) {
    let displaySetInstanceUIDs = [];
    for (let i = 0; i < datos.length; i++) {
      displaySetInstanceUIDs.push(datos[i].displaySetInstanceUID);
    }
    for (let a = 0; a < displaySetInstanceUIDs.length; a++) {
      //Seleciono el styduID:
      let idEncontrado = displaySetInstanceUIDs.indexOf(
        displaySetInstanceUIDs[a],
        [a + 1]
      );
      //Si es distinto de -1 es que ha encontrado uno similar, lo cual lo borramos de datos, este nos devuelve el id del identico:
      if (idEncontrado != -1) {
        datos.splice(idEncontrado, 1);
        displaySetInstanceUIDs.splice(idEncontrado, 1);
        a--;
      }
    }
    return datos;
  }

  //Crea el zip:
  async crearZip(datos) {
    for (let i = 0; i < datos.length; i++) {
      //Recorre las series pasando las imagenes y y el nombre de la serie, esto crea un zip por cada serie:
      this.descarga(datos[i].imageIds, datos[i].serie);
    }
  }

  //Descarga las series, cada serie descargara un zip con sus datos:
  async descarga(urls, nombre) {
    //Crear el zip:
    let zip = new JSZip();
    let count = 0;
    //Nombre del Estudio:
    let zipFilename = nombre + '.zip';
    urls.forEach(function(url) {
      let filename = ImagenesDownload.textoImagen(url);
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count == urls.length) {
          zip.generateAsync({ type: 'blob' }).then(function(content) {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  }

  //Devuelve el nombre de la serie:
  nombreSerie(ruta) {
    let posicionInicio = ruta.search('&seriesUID=') + 11; //Se suma 11 ya que son los caracteres de este string y el id empieza despues de esto
    let posicionFinal = ruta.search('&objectUID');
    let nombre = ruta.slice(posicionInicio, posicionFinal);
    return nombre;
  }

  //Devuelve el nombre del fichero que va a tener la imagen:
  static textoImagen(ruta) {
    let posicionInicio = ruta.search('&objectUID=') + 11; //Se suma 11 ya que son los caracteres de este string y el id empieza despues de esto
    let posicionFinal = ruta.search('&contentType=');
    let nombre = ruta.slice(posicionInicio, posicionFinal) + '.dcm';
    return nombre;
  }

  //Carga los ficheros con la ruta y su nombre:
  load(file, nombre) {
    //Espera a que tenga la precarga descargada con el blob y una vez lo tiene ya lo descarga:
    this.getBlob(file).then(blob => {
      this.saveAs(blob, nombre);
    });
  }

  //Creoa el trabajo de descargar pero internamente:
  getBlob(url) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };

      xhr.send();
    });
  }

  //Descarga ya los ficheros:
  saveAs(blob, filename) {
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
  //------------------------------------------------------------------------------------------------
}
export default ImagenesDownload;
