import cornerstoneTools from 'cornerstone-tools';
import cornerstone from 'cornerstone-core';
import LayoutButton from '../../../ui/src/components/layoutButton/LayoutButton';

//Auxiliar para guardar estado de si esta activado o no:
var habilitado;

/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 */
class LineasReferencia {
  constructor() {
    //False es desactivado, true es activado:
    this.estado = false;
  }

  iniciarLineas() {
    //Si es true, es que se ha deshabilitado la herramienta al cargar mas layout, con lo cual hay que volver a habilitarlo.
    if (LayoutButton.deshabilitado()) {
      //Una vez comprobado que esta deshabilitado por viewport lo activo:
      this.estado = false;
      //Y pongo que deshabilitado es 0 false ya que sino se queda en true:
      LayoutButton.deshabilitado(0);
    }
    //Activar:
    if (!this.estado) {
      //Pongo las vistas
      const vistas = Array.from(
        document.getElementsByClassName(
          'viewport-drop-target viewport-container'
        )
      );
      //Solo hago las lineas de referencias si hay mas de 1 vista:
      if (vistas.length > 1) {
        //Sacar los datos:
        //Saco los elementos que son las vistas:
        let elements = this.sacarElementos(vistas);
        //De esos elementos saco los Ids de las imagenes:
        let imageId = this.sacarImageId(this.sacarStacks(elements));
        //De los id saco los stack de datos:
        let stackDatos = this.crearStack(imageId);
        //Ya con los datos cargo las lineas de referencia:
        this.cargarDatos(elements, stackDatos);
        habilitado = true;
      }
    } else {
      cornerstoneTools.setToolDisabled('ReferenceLines');
      this.estado = false;
      habilitado = false;
    }
  }

  //Saco las vistas que hay mostradas en pantalla:
  sacarElementos(vistas) {
    //Puede haber varias vistas, las cuales las voy a guardar en un nuevo array para asi luego syncronizarlas y poder poner las lineas:
    let elementos = [];
    for (let i = 0; i < vistas.length; i++) {
      //Puede haber vistas nulas, si es asi esas no las cargo:
      if (vistas[i].children[2].children[1]) {
        //Viewports:
        elementos.push(vistas[i].children[2].children[1]);
      }
    }
    return elementos;
  }

  //Saco los stack segun las vistas sacadas:
  sacarStacks(elementos) {
    //Saco los datos del local storage para crear los stack de datos:
    let stack = [];
    for (let a = 0; a < elementos.length; a++) {
      let textoGuardado = 'viewportData' + a;
      stack.push(JSON.parse(localStorage.getItem(textoGuardado)));
    }
    return stack;
  }

  //De los stack saco la image ID
  sacarImageId(stack) {
    //Saco los datos del local storage para crear los stack de datos:
    let imageId = [];
    for (let b = 0; b < stack.length; b++) {
      //Puede que tenga abiertos visores vacios, si es asi odmito los nullos:
      if (stack[b]) {
        imageId.push(stack[b].stack.imageIds);
      }
    }
    return imageId;
  }

  //Creo los stack de datos con la imageID segun los viewport que tenga
  crearStack(imageId) {
    //Crear los Stack de datos:
    let stackDatos = [];
    for (let c = 0; c < imageId.length; c++) {
      //Crear los Stack de datos con sus respectivas imagenes e imagen principal:
      let stack = {
        currentImageIdIndex: 0,
        imageIds: imageId[c].map(seriesImage => `${seriesImage}`),
      };
      stackDatos.push(stack);
    }
    return stackDatos;
  }

  //Carga los datos y hace que aparezcan las lineas de referencia:
  async cargarDatos(elements, stackDatos) {
    //Creo el syncronizador ya que hay que sincronizar el scroll con las lineas:
    const synchronizer = new cornerstoneTools.Synchronizer(
      'cornerstonenewimage',
      cornerstoneTools.updateImageSynchronizer
    );

    //Lista de elementos a crear:
    let promise = [];
    //Entra en cargando cada vista:
    for (let i = 0; i < elements.length; i++) {
      //A este array que creamos le añadidmos las vistas de bucle, pero se las añadimos con un await ya que tienen que cargar y estas pueden tardar:
      promise.push(
        await cornerstone.loadImage(stackDatos[i].imageIds[0]).then(image => {
          cornerstone.displayImage(elements[i], image);

          // set the stack as tool state
          synchronizer.add(elements[i]);
          cornerstoneTools.addStackStateManager(elements[i], [
            'stack',
            'Crosshairs',
          ]);
          cornerstoneTools.addToolState(elements[i], 'stack', stackDatos[i]);
        })
      );
    }
    //Como en la lista todavia no se ha cargado por que tiene await, en el Promise, es decir mientras esperamos tambien ponemos un await haciendo
    //Que primero se cargen los datos de cada vista en el array y cuando este este al completo aparezcan las lineas de sync:
    await Promise.all([promise]).then(() => {
      cornerstoneTools.setToolEnabled('ReferenceLines', {
        synchronizationContext: synchronizer,
      });
      this.estado = true;
      this.quitarVista();
    });
  }

  //Nada mas terminar de cargar tenemos que quitar las lineas de referencia de la vista principal, para que no se muestre.
  quitarVista() {
    //Saco el activado:
    //Y este le desactivo ya que no quiero que se muestre lineas en el que estoy posicionado actualmente:
    const vistaActivada = Array.from(
      document.getElementsByClassName(
        'viewport-drop-target viewport-container active'
      )
    );
    let elementoActivado = vistaActivada[0].children[2].children[1];
    cornerstoneTools.setToolDisabledForElement(
      elementoActivado,
      'ReferenceLines'
    );
  }

  //Es un metodo estatico ya que le llamaremos en el evento de cambiar el viewport selecionado.
  //Como nos genera una linea en medio, vamos a hacer que cada vez que se cambie el viewport selecionado, los demas dibujen la linea, pero el que esta selecionado se quite dicha linea:
  static async recargarVistas() {
    //Solo recargo la vista si esta habilitada la herramienta:
    if (habilitado) {
      //Scar los viewport:
      //Saco todos los viewports y activo los elementos:
      const vistas = Array.from(
        document.getElementsByClassName(
          'viewport-drop-target viewport-container'
        )
      );

      //Activo los que tienes que estar activados:
      for (let i = 0; i < vistas.length; i++) {
        let elemento = vistas[i].children[2].children[1];
        await cornerstoneTools.setToolEnabledForElement(
          elemento,
          'ReferenceLines'
        );
      }
      //Quito las lineas de la vista principal:
      //Saco el activado:
      //Y este le desactivo ya que no quiero que se muestre lineas en el que estoy posicionado actualmente:
      const vistaActivada = Array.from(
        document.getElementsByClassName(
          'viewport-drop-target viewport-container active'
        )
      );
      let elementoActivado = vistaActivada[0].children[2].children[1];
      await cornerstoneTools.setToolDisabledForElement(
        elementoActivado,
        'ReferenceLines'
      );
      //Limpio la consola ya que al ser asyncrono nos salen warinings de que aun no sincronizo:
      console.clear();
    }
  }

  //Deshabilitar todas las vistas, ya que al añadir o quitar viewports hay que quitar todas las lineas una a una, ya que se han habilitado por elementos:
  static async deshabilitarVistas() {
    if (habilitado) {
      const vistas = Array.from(
        document.getElementsByClassName(
          'viewport-drop-target viewport-container'
        )
      );
      //Activo los que tienes que estar activados:
      for (let i = 0; i < vistas.length; i++) {
        let elemento = vistas[i].children[2].children[1];
        await cornerstoneTools.setToolDisabledForElement(
          elemento,
          'ReferenceLines'
        );
      }
      cornerstoneTools.setToolDisabled('ReferenceLines');
      habilitado = false;
      this.estado = false;
    }
  }

  //Devuelve si essta habilitado o no:
  static getHabilitado() {
    return habilitado;
  }
}
export default LineasReferencia;
