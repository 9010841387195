/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 * Lee un fichero XML que tenemos en el propio proyecto y filtra los datos para que no se muestren ficheros que puedan cascar.
 */
class ArchivosIncompatibles {
  constructor() {
    this.seriesDescription = [];
    this.altImageText = [];
    this.json = '';
    this.leerDatos();
  }

  //Leer datos:
  leerDatos() {
    try {
      //si esta en producion o en depurar:
      if (process.env.NODE_ENV == 'production') {
        let url = window.location.origin + '/ficheroIncompatibles.json';
        //Para la peticion con un promise y cuando tengo resultado recorro los datos:
        this.getJSON(url).then(res => {
          this.json = res;
          this.recorrerFichero();
        });
      } else {
        //Datos del fichero en desarrollo:
        this.json = require('./ficheroIncompatibles.json');
        this.recorrerFichero();
      }
    } catch (ex) {
      //Error:
      //Datos del fichero en en caso de que falle la busqueda online:
      this.json = require('./ficheroIncompatibles.json');
      this.recorrerFichero();
    }
  }

  //Descarga el fichero JSON que hay en la web con los filtros:
  getJSON(url) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'json';
      xhr.onload = function() {
        var status = xhr.status;
        //Si recive datos:
        if (status == 200) {
          return resolve(xhr.response);
        }
      };

      xhr.send();
    });
  }

  //Recorrer fichero y guardar los datos encontrados:
  recorrerFichero() {
    //Si tiene datos el fichero:
    if (this.json.length > 0) {
      let parseJson = JSON.parse(JSON.stringify(this.json[0]));
      //Series:
      parseJson.SeriesDescription.forEach(element => {
        this.seriesDescription.push(element);
      });
      //Imagenes:
      parseJson.altImageText.forEach(element => {
        this.altImageText.push(element);
      });
    }
  }

  //Busca a ver si existe en la lista:
  existeEnSeries(serie) {
    let encontrado = false;
    this.seriesDescription.forEach(s => {
      if (serie.includes(s)) {
        encontrado = true;
      }
    });
    return encontrado;
  }

  //Busca a ver si existe en la lista:
  existeEnImg(img) {
    let encontrado = false;
    this.altImageText.forEach(i => {
      if (img.includes(i)) {
        encontrado = true;
      }
    });
    return encontrado;
  }
}
export default ArchivosIncompatibles;

//ESTRUCTURA DE DATOS:
/*
  [
    {
      "altImageText": ["SR", "DOC"],
      "SeriesDescription": ["Informe de dosis", "Report Data"]
    }
  ]
  */
