import React, { PureComponent } from 'react';

import { LayoutChooser } from './LayoutChooser.js';
import PropTypes from 'prop-types';
import ToolbarButton from '../../viewer/ToolbarButton';
import LineasReferencia from '../../../../viewer/src/misProgramas/lineasReferencia.js';
import SyncWwwc from '../../../../viewer/src/misProgramas/syncWWWC/syncWwwc.js';

//Auxiliar para guardar estado de los viewports anteriores:
var aux;
var auxVistas = 1;
var primeraVez = true;
var deshabilitado;
export class LayoutButton extends PureComponent {
  static defaultProps = {
    dropdownVisible: false,
  };

  static propTypes = {
    dropdownVisible: PropTypes.bool.isRequired,
    /** Called with the selectedCell number when grid sell is selected */
    onChange: PropTypes.func,
    /** The cell to show as selected */
    selectedCell: PropTypes.object,
  };

  state = {
    dropdownVisible: this.props.dropdownVisible,
  };

  componentDidUpdate(prevProps) {
    if (this.props.dropdownVisible !== prevProps.dropdownVisible) {
      this.setState({
        dropdownVisible: this.props.dropdownVisible,
      });
    }
  }

  onClick = () => {
    this.setState({
      dropdownVisible: !this.state.dropdownVisible,
    });
  };

  onChange = selectedCell => {
    if (this.props.onChange) {
      this.props.onChange(selectedCell);
      //------------------------------------------------------------------------------------------------------------
      //Quitar las lineas de referencia:
      if (primeraVez) {
        aux = selectedCell;
        primeraVez = false;
      }
      //Al reducir la vista borro de local storage los datos que se han guardado:
      //Saco el numero de vista que tenia anteriormete:
      let nVistasActual = (selectedCell.row + 1) * (selectedCell.col + 1);

      //Guardo el numero de vista actuales en una variable aux:
      if (auxVistas > nVistasActual) {
        //Borro del local storage los datos que ya no se estan mostrando:
        for (let i = auxVistas; i > nVistasActual - 1; i--) {
          let nombre = 'viewportData' + i;
          localStorage.removeItem(nombre);
        }
      }
      //Guardo en aux el numero de vistas:
      auxVistas = nVistasActual;

      if (aux.col != selectedCell.col || aux.row != selectedCell.row) {
        //Quito las lineas de referencia:
        LineasReferencia.deshabilitarVistas();
        //Quitamos la SYNC de WWWC:
        SyncWwwc.quitarSync(true);
        //Decimos que deshabilitado esta en true, ya que se ha quedado quitada la linea de referencias pero con estado habilitado:
        deshabilitado = true;
        //Guarda la variable de nuevo y quita las lineas de referencia ya que se añadio el viewport:
        aux = selectedCell;
      } else {
        deshabilitado = false;
      }
      //------------------------------------------------------------------------------------------------------------
    }
  };

  //Variable globar para saber cuando se ha cambiado el estado:
  static deshabilitado(estado) {
    if (estado == 0) {
      deshabilitado = false;
    }
    if (estado == 1) {
      deshabilitado = true;
    }
    return deshabilitado;
  }

  render() {
    return (
      <div className="btn-group">
        <ToolbarButton
          isActive={this.state.dropdownVisible}
          label={'Layout'}
          icon="th"
          onClick={this.onClick}
        />
        <LayoutChooser
          visible={this.state.dropdownVisible}
          onChange={this.onChange}
          onClick={this.onClick}
          selectedCell={this.props.selectedCell}
        />
      </div>
    );
  }
}

export default LayoutButton;
