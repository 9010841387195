//import ar from './ar/';
import en from './en/';
//import en_US from './en-US/';
import es from './es/';
/*import fr from './fr/';
//import ja_JP from './ja-JP/';
import nl from './nl/';
import pt_BR from './pt-BR/';
//import vi from './vi/';
import zh from './zh/';
*/
export default {
  //  ...ar,
  ...en,
  //  ...en_US,
  ...es,
  /* ...fr,
//  ...ja_JP,
  ...nl,
  ...pt_BR,
//  ...vi,
//  ...zh,*/
};
