import contextMenu from './context-menu';
import csTools from 'cornerstone-tools';
import { defaultState } from '../../../../core/src/redux/reducers/preferences';
import cornerstoneTools from 'cornerstone-tools';
import cornerstone from 'cornerstone-core';

//Activa o cambia las WWWC por defecto que hay con lso botones:
var items = [];
//Variable de la vista de opciones:
var cm1;
//Variable activado la sync:
var wwwcActivado = false;
//Syncronizacion WWWC:
var synchronizerWWWC;

/**
 * @author Arturo Rodrigo <arodrigo@irerayosx.com>
 * Desplegable para syncronizar junto con el las imagenes el WWWC
 */
class SyncWwwc {
  //MOSTRAR DESPLEGABLE:
  //-----------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------
  constructor() {
    this.crearItems();
  }

  //Activa la herramienta:
  activar() {
    csTools.setToolDisabled('Zoom', { mouseButtonMask: 2 });
    cm1 = new contextMenu('.cornerstone-canvas', items);
    SyncWwwc.elemento(); //Modifico la opacidad para que no este activado.
  }

  //Quita la herramienta:
  desactivar() {
    try {
      cm1.destroy();
      //Habilitamos de nuevo el zoom:
      csTools.setToolActive('Zoom', { mouseButtonMask: 2 });
    } catch (e) {
      //No hacer nada.
    }
  }

  //Genera los items segun los que esten guardados:
  crearItems() {
    //La lista siempre tiene 10 elementos:
    for (var i = 1; i <= 10; i++) {
      //Si tiene nombre lo añado:
      if (defaultState.windowLevelData[i].description) {
        let elm = {
          name: defaultState.windowLevelData[i].description,
          id: i,
          fn: function() {
            SyncWwwc.cambioWWWC(elm.id); //Paso del elemento el id, ya que si paso la posicion da error.
          },
        };
        items.push(elm);
      }
    }
    //Al finalizar añadir la sync WWWC:
    let btnSync = {
      name: 'Sync WWWC',
      fn: function() {
        SyncWwwc.activarDesactivarWWWC();
      },
    };
    items.push(btnSync);
  }

  //Cambiar WWWC:
  static cambioWWWC(num) {
    //Simula la ejecucion de tecla para cambiar el WWWC:
    let text = num.toString();
    let charCode = text.charCodeAt(0);
    let code = 'Digit' + text;
    let event = new KeyboardEvent('keypress', {
      isTrusted: true,
      bubbles: true,
      cancelable: true,
      composed: true,
      eventParse: 2,
      charCode: charCode,
      keyCode: charCode,
      which: charCode,
      key: text,
      code: code,
    });
    document.dispatchEvent(event);
  }

  //Seleciona el elemento WWWWC para cambiar la opacidad si esta activado o desactivado.
  static elemento() {
    let lista = document.getElementsByClassName('ContextMenuWWWC-item');
    let chkWWWC = document.getElementById('cboxWWWC');
    let elemento = lista[lista.length - 1];
    if (wwwcActivado) {
      elemento.style.opacity = 1;
      chkWWWC.checked = true;
    } else {
      elemento.style.opacity = 0.5;
      chkWWWC.checked = false;
    }
  }

  //Cambia el estado de activado o desactivado el WWWC:
  static activarDesactivarWWWC() {
    wwwcActivado = !wwwcActivado;
    this.elemento();
    if (wwwcActivado) {
      this.iniciarSYNCWWWC();
    } else {
      this.quitarSync();
    }
  }
  //-----------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------

  //EVENTOS SYNC:
  //-----------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------
  //Crea la sincronizaciopn por primera vez:
  static iniciarSYNCWWWC() {
    //Al dar init da un warning en consola de COLORLUT, para que no se llene la consola de warnings se agrega esto antes de incializar:
    cornerstoneTools.getModule(
      'segmentation'
    ).configuration.segmentsPerLabelmap = 0;
    //Iniciar corstone tools con la syncronizacion activada:
    cornerstoneTools.init({
      globalToolSyncEnabled: true,
    });

    //Sincronizacion de WWWC:
    synchronizerWWWC = new cornerstoneTools.Synchronizer(
      'cornerstoneimagerendered',
      cornerstoneTools.wwwcSynchronizer
    );

    //Lista de elementos activos:
    const listaVieport = this.vistas();
    //Inicializo la syncronizacion para agregar imagenes pongo true:
    this.inicializarSync(listaVieport, true);
  }

  //Inicializa la syncronizacion pasando la lista de imagenes:
  static inicializarSync(listaVieport, opcion) {
    //Como siempre le paso la lista de vieport aunque alguno ya este syncronizado, vamos a desincronizar todo y luego volver a suncronizar:
    //En caso de que opcion sea false ya tenemos desincronizado todo.
    for (let i = 0; i < listaVieport.length; i++) {
      let imagenBorrar = listaVieport[i];
      //Ponemos try catch por que a la hora de borrar aunque no sea nulo puede que casque, asi evitamos que se llene el consolelog de datos:
      try {
        //Quitamos la syncronizacion:
        synchronizerWWWC.remove(imagenBorrar);
      } catch (e) {
        let a = e;
      }
    }

    //Si hay que syncronizar añadimos la lista, una vez ya quitada:
    if (opcion) {
      //Añado a la sync las imagenes:
      for (let b = 0; b < listaVieport.length; b++) {
        let imagen = listaVieport[b];
        synchronizerWWWC.add(imagen);
      }
    }
  }

  //Si se quita la sync:
  static quitarSync(vistaCambiada) {
    if (!vistaCambiada) {
      synchronizerWWWC.destroy();
      synchronizerWWWC = null;
    } else {
      if (wwwcActivado) {
        synchronizerWWWC.destroy();
        synchronizerWWWC = null;
        //Si no tiene sync pero se ha dado a quitar es por que se a agregado/quitado viewports:
        wwwcActivado = !wwwcActivado;
        this.elemento();
      }
    }
    //Tambien restauro la vista que tenia por defecto:
    let vistas = this.vistas();
    for (let i = 0; i < vistas.length; i++) {
      cornerstone.reset(vistas[i]);
    }
  }

  //Saca las vistas que hay:
  static vistas() {
    //Selecionar las vistas:
    const vistas = Array.from(
      document.getElementsByClassName('viewport-drop-target viewport-container')
    );
    const listaVieport = new Array();
    for (let a = 0; a < vistas.length; a++) {
      //Puede haber vistas vacias:
      if (vistas[a].children[2].children[1]) {
        listaVieport.push(vistas[a].children[2].children[1]);
      }
    }
    return listaVieport;
  }
}
export default SyncWwwc;
